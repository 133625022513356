import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as xml2js from 'xml2js';
import { of, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CoreService {
    certificates: Certificate[] = []
    fetchedUsers: Array<string> = []
    users: { [key: string]: User } = {}

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

    getBlob(filename: string) {
        this.http.post(environment.logicAppEndpoint,{
            action: "GET_CERT",
            cert_name: filename
        }, {
            headers: {
                "x-ms-version": "2017-11-09"
            },
            responseType: 'blob'
        }).subscribe(x => {
            if (this.certificates) {
                let cert = this.certificates.find(cert => cert.filename == filename)
                if (cert) {
                    cert.base64 = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(x))
                }
            }

        })
    }


    isFetchingCert = false;
    isFetchedCert = false
    getAllCertificates(): Observable<Certificate[]> {
        console.log("GETTING ALL CERTS");
        console.log(this.certificates);
        if (this.isFetchedCert) {
            
            
            return of(this.certificates)
        }

        if (!this.isFetchingCert) {
            this.isFetchingCert = true
            return this.http.post(environment.logicAppEndpoint, {
                action: "LIST_CERT"
            }, {
                responseType: 'text'
            }).pipe(
                map(x => {
                    let ret: Certificate[] = []
                    let json: any[] = []
                    
                    
                    xml2js.parseString(x, function (err, result) {
                        
                        if(result['EnumerationResults']['Blobs'][0] != "") {
                            json = result['EnumerationResults']['Blobs'][0]['Blob'];
                        } else {
                            json = []
                        }
                        
                    });

                    ret = json.map(each => {
                        let title = "[No title]"
                        let platform = "N/A"
                        let fileName = each['Name'][0].split(".")
                        try { platform = fileName[0].split("_")[0] } catch { }
                        try { title = fileName[0].split("_")[1] } catch { }
                        
                        this.getBlob(each['Name'][0])
                        return {
                            id: fileName[0],
                            title: title,
                            base64: '',
                            filename: each['Name'][0],
                            platform: platform
                        }
                    })
                    console.log(ret);
                    
                    this.isFetchedCert = true
                    this.certificates = ret
                    return ret
                }))
        } else {
            return of(this.certificates)
        }

    }


    getCertByUser(email: string): Observable<Array<UserCertificate>> {
        console.log("GETTING CERT BY USER");

        return this.getFilteredCerts(email)
    }

    getFilteredCerts(userId: string | null = null): Observable<Array<UserCertificate>> {
        let filterQuery = ''
        let filters = []
        if (userId != null) {
            filters.push(`email eq '${userId}'`)
        }
        if (filters.length > 0) {
            filterQuery = filters.join(',')
        }


        return this.http.post<Array<ODataUserCertificate>>(environment.logicAppEndpoint, {
            action: "GET",
            filter: filterQuery
        }).pipe(
            map(data => {
                

                return data.map(each => {
                    
                    return {
                        cert_id: each.cert_id,
                        cert_date: each.cert_date,
                        email: each.email,
                        verification_link: each.verification_link
                    }
                })
            })
        )
    }

    getUser(upn: string): Observable<User> {
        if (!(this.fetchedUsers.includes(upn))) {
            this.fetchedUsers.push(upn)
            this.http.get<any>(`https://graph.microsoft.com/v1.0/users/${upn}`).subscribe(x => {
                console.log("in map");

                let temp = {
                    email: upn,
                    name: x['displayName'],
                    base64: ''
                }
                this.http.get<any>(`https://graph.microsoft.com/beta/users/${upn}/photo/$value`, {
                    responseType: 'blob' as 'json'
                }).subscribe(pic => {
                    this.users[upn].base64 = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(pic))
                })
                this.users[upn] = temp

            }, error => {
                let temp = {
                    email: upn,
                    name: '[Deleted user] ' + upn,
                    base64: ''
                }
                this.users[upn] = temp
            })
            return of(this.users[upn])
        } else {
            return of(this.users[upn])
        }

    }

    getProfilePicture(upn: string) {
        return this.http.get<any>(`https://graph.microsoft.com/beta/users/${upn}/photo/$value`, {
            responseType: 'blob' as 'json'
        }).pipe(map(x => {
            return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(x))
        }))
    }

}

export class Certificate {
    filename: string = ''
    id: string = 'N/A'
    title: string = '[Not available]'
    base64: SafeUrl = ''
    platform: string = 'N/A'
}

export class User {
    name: string = ''
    email: string = ''
    base64: SafeUrl = ''
}

export class UserCertificate {
    cert_id: string = ''
    cert_date: string = ''
    email: string = ''
    verification_link: string = ''
}

interface ODataUserCertificate {
    PartitionKey: string
    RowKey: string
    Timestamp: Date
    cert_date: string
    cert_id: string
    email: string
    verification_link: string
}