import { Component, OnInit } from '@angular/core';
import { CoreService, Certificate, UserCertificate } from '../services/core.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-certs',
  templateUrl: './certs.component.html',
  styleUrls: ['./certs.component.scss']
})
export class CertsComponent implements OnInit {

  userCerts: UserCertificate[] = []
  filteredUserCerts: UserCertificate[] = []
  certificates: Certificate[] = [];
  selectedCert: string | null = null;
  selectedUser: string | null = null;
  selectedPlatform: string | null = null;
  certOptions: CertificateDropdown[] = []
  certOptionsDisplay: CertificateDropdown[] = []
  platformOptions: string[] = []
  userOptions: CertificateDropdown[] = []
  selectedSort: string = 'date-desc'
  isFiltering = false
  loaded = false
  sortOptions: CertificateDropdown[] = [
    {
      name: 'Certificate name: ascending',
      value: 'cert-asc'
    },
    {
      name: 'Certificate name: descending',
      value: 'cert-desc'
    },
    {
      name: 'Completed date: most recent',
      value: 'date-desc'
    },
    {
      name: 'Completed date: oldest first',
      value: 'date-asc'
    },
    {
      name: 'Email: A-Z',
      value: 'email-asc'
    },
    {
      name: 'Email: Z-A',
      value: 'email-desc'
    }
  ]

  groupedCerts: UserCertificateGroup[] = []

  constructor(public core: CoreService, private http: HttpClient, private spinner: NgxSpinnerService) {
    this.bootstrap()

  }

  async ngOnInit() {
  }

  async bootstrap() {
    this.spinner.show()
    this.userCerts = await this.core.getFilteredCerts().toPromise()
    this.filteredUserCerts = JSON.parse(JSON.stringify(this.userCerts))
    this.certificates = await this.core.getAllCertificates().toPromise()

    this.certOptions = this.certificates.map(x => {
      return {
        name: `[${x.platform}] ${x.title}`,
        value: x.id
      }
    })

    this.platformOptions = this.certificates.map(x => x.platform)
    this.platformOptions = this.platformOptions.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    let userTemp = this.userCerts.map(x => x.email)


    userTemp = userTemp.filter((value, index, self) => {
      return self.indexOf(value) === index;
    })
    this.userOptions = userTemp.map(x => {
      return {
        name: `${x}`,
        value: `${x}`
      }
    })
    this.createCertGroup()

    this.spinner.hide()
    this.loaded = true

  }

  resetFilters() {
    this.isFiltering = false
    this.selectedCert = null
    this.selectedUser = null
    this.selectedPlatform = null
    this.selectedSort = 'date-desc'
    this.filterUserCerts()
  }

  filterUserCerts() {
    this.filteredUserCerts = JSON.parse(JSON.stringify(this.userCerts))
    this.isFiltering = false
    if (this.selectedCert != null) {
      this.isFiltering = true

      this.filteredUserCerts = this.filteredUserCerts.filter(uc => uc.cert_id == this.selectedCert)
    }
    if (this.selectedUser != null) {
      this.isFiltering = true

      this.filteredUserCerts = this.filteredUserCerts.filter(uc => uc.email == this.selectedUser)
    }

    if (this.selectedPlatform != null) {
      this.isFiltering = true

      this.filteredUserCerts = this.filteredUserCerts.filter(uc => uc.cert_id.split("_")[0] == this.selectedPlatform)


      this.certOptions = this.certificates.filter(c => c.platform == this.selectedPlatform).map(x => {
        return {
          name: `${x.title}`,
          value: x.id
        }
      })
      console.log(this.certOptions);


    } else {
      this.certOptions = this.certificates.map(x => {
        return {
          name: `[${x.platform}] ${x.title}`,
          value: x.id
        }
      })
    }
    this.createCertGroup()
  }

  selectCertAndFilter(cert: UserCertificate) {
    this.selectedCert = cert.cert_id
    this.filterUserCerts()
  }
  selectPlatformAndFilter(platform: string) {
    this.selectedPlatform = platform

    this.filterUserCerts()

  }

  selectUserAndFilter(cert: UserCertificate) {
    this.selectedUser = cert.email
    this.filterUserCerts()
  }



  sortCert() {
    let sortBy = this.selectedSort.split("-")[0]
    let sortOrderAsc = this.selectedSort.split("-")[1] == "asc"

    this.filteredUserCerts.sort((a, b) => {
      if (sortBy == 'date') {
        return Date.parse(b.cert_date) - Date.parse(a.cert_date)
      } else {
        return b.cert_id.localeCompare(a.cert_id)
      }
    })
    if (sortOrderAsc) {
      this.filteredUserCerts = this.filteredUserCerts.reverse()
    }
    this.createCertGroup()
  }

  createCertGroup() {

    //CREATE TEMP DICT WITH KEY OF GROUP
    let temp: { [key: string]: UserCertificate[] } = {}
    let sortBy = this.selectedSort.split("-")[0]
    let sortOrderAsc = this.selectedSort.split("-")[1] == "asc"

    this.filteredUserCerts.forEach(uc => {
      let key = ''
      if (sortBy == 'cert') {
        key = this.getCertById(uc.cert_id).title
      }
      if (sortBy == 'date') {
        const months: { [key: number]: string } = {
          0: 'January',
          1: 'February',
          2: 'March',
          3: 'April',
          4: 'May',
          5: 'June',
          6: 'July',
          7: 'August',
          8: 'September',
          9: 'October',
          10: 'November',
          11: 'December'
        }
        let date = new Date(uc.cert_date)
        key = months[date.getMonth()] + " " + date.getFullYear()
      }
      if (sortBy == 'email') {
        key = uc.email
      }
      if (key in temp) {
        temp[key].push(uc)
      } else {
        temp[key] = [uc]
      }
    })


    // SORT KEY BY SELECTED SORT

    let keys = Object.keys(temp).sort((a, b) => {
      if (sortBy == 'date') {
        return Date.parse(b) - Date.parse(a)
      } else {
        return b.localeCompare(a)
      }
    })


    if (sortOrderAsc) {
      keys = keys.reverse()
    }

    // CREATE LIST WITH SORTED KEY    
    this.groupedCerts = []
    keys.forEach(key => {

      let cert = this.certificates.find(c => c.id == key)
      let extendedName = null
      if (cert) {
        extendedName = cert.title
      }
      this.groupedCerts.push({
        name: key,
        extendedName: extendedName,
        certs: temp[key]
      })


    })
    console.log(this.groupedCerts);



  }

  getCertById(certId: string): Certificate {
    let cert = this.certificates.find(cert => cert.id == certId)
    return cert || new Certificate()
  }

  getUserCertCount(certId: string): number {
    return this.userCerts.filter(uc => uc.cert_id == certId).length
  }



}

class CertificateDropdown {
  name: string = ''
  value: string = ''
}

class UserCertificateGroup {
  name: string = ''
  extendedName: string | null = null
  certs: UserCertificate[] = []
}
