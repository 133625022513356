<div class="container">
    <h1>Add certificate</h1>

    <div class="search-box" [ngClass]="{'disabled': certId != ''}">
        <span><i class="bi bi-search"></i> </span>
        <input type="text" [(ngModel)]="keyword">


    </div>

    <div class="certificates" [ngClass]="{'disabled': certId != ''}">
        <div class="certificate" *ngFor="let cert of certificates | filter: keyword" (click)="selectCertificate(cert)"
            [ngClass]="{'active': cert == selectedCert, 'disabled': isAdded(cert.id)}">
            <img class="certificate__image" [src]="cert.base64"
                onerror="this.src='/assets/images/cert-placeholder.png'">
            <div class="certificate__text">
                <div class="certificate__id" [innerHTML]="cert.platform | highlight:keyword"></div>
                <div class="certificate__name" [innerHTML]="cert.title | highlight:keyword"></div>
            </div>
        </div>
    </div>
    <label for="" class="input-field">
        <span>Completion date</span>
        <input type="date" [(ngModel)]="date">
    </label>
    <label for="" class="input-field">
        <span>Verification link (optional)</span>
        <small>Got a proof? Paste the link below.
            <br>example: https://www.credly.com/badges/00000000-0000-0000-0000-000000000000/public_url</small>
        <input type="text" [(ngModel)]="link" name="field" #linkField="ngModel" url class="full-width" />
        <small class="error" *ngIf="linkField.errors?.url">Please enter valid URL or leave this field blank</small>
    </label>
    <div class="actions">
        <div class="action" (click)="add()" [ngClass]="{'disabled': !isValid() || !linkField.valid}">
            {{verb | titlecase }}</div>
        <div class="action action--secondary" [routerLink]="'/my'">Cancel</div>
    </div>

</div>