import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserInfoComponent } from './my/my.component';
import { MsalGuard } from '@azure/msal-angular';
import { CertsComponent } from './certs/certs.component';
import { AddCertComponent } from './add-cert/add-cert.component';

const routes: Routes = [
  {
    path: '',
    component: CertsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'my',
    component: UserInfoComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'edit',
    component: AddCertComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'add',
    component: AddCertComponent,
    canActivate: [MsalGuard]
  }

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
