<h1 *ngIf="isOwner">My certificates</h1>
<h1 *ngIf="!isOwner">{{account.name}}</h1>
<div class="actions" *ngIf="isOwner">
    <div class="action" [routerLink]="'/add'"><i class="bi bi-plus"></i> Add</div>
</div>
<div class="certificates">
    <div class="certificate" *ngFor="let cert of certificates">

        <img class="certificate__image" [src]="cert.base64" onerror="this.src='../assets/images/cert-placeholder.png'">
        <div class="certificate__text">
            <div class="certificate__id">{{cert.platform}}</div>
            <div class="certificate__name">{{cert.title}}</div>
            <div class="certificate__date"><i class="bi bi-clock"></i>Completed on
                {{getMyCert(cert.id)?.cert_date | date}}
                <a [href]="getMyCert(cert.id)?.verification_link" *ngIf="getMyCert(cert.id)?.verification_link" target="_blank"
                    class="verified"><i class="bi bi-patch-check-fill"></i></a>
            </div>

            <div class="certificate__description">
            </div>
            <div class="certificate__footer" *ngIf="isOwner">

                <div class="certificate__button" (click)="edit(cert.id)">

                    <i class="bi bi-pen"></i>Edit
                </div>

                <div class="certificate__button certificate__button--danger" (click)="delete(cert.id)">

                    <i class="bi bi-trash"></i>Delete
                </div>
                <ngx-spinner [fullScreen]="false" [name]="cert.id"></ngx-spinner>

            </div>
        </div>


    </div>
</div>


<div class="no-content" *ngIf="loaded && certificates.length == 0">
    Doesn't seem like you have anything here. However, the add button is right up there.
</div>