import { Component, OnInit } from '@angular/core';
import { CoreService, Certificate, UserCertificate } from '../services/core.service';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-cert',
  templateUrl: './add-cert.component.html',
  styleUrls: ['./add-cert.component.scss']
})
export class AddCertComponent implements OnInit {
  certificates: Certificate[] = []
  selectedCert: Certificate | undefined | null
  myCerts: UserCertificate[] = []
  keyword: string = ''
  userId: string = ''
  certId: string = ''
  date: string = ''
  link: any = ''
  verb: string = 'ADD'

  constructor(private core: CoreService, private http: HttpClient, private auth: MsalService, private spinner: NgxSpinnerService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.bootstrap()
    this.route.queryParamMap.subscribe(async paramMap => {
      let certId = paramMap.get('certId') || '';
      this.certId = certId
      this.keyword = certId

      let certDate = paramMap.get('certDate') || '';
      if(certDate != '') {
        this.date = certDate
      }

      let verificationLink = paramMap.get('verificationLink') || '';
      console.log(verificationLink);
      
      if(verificationLink != '') {
        this.link = verificationLink
      }

      if(this.certId  != '') {
        this.verb = 'UPDATE'
      }
      
    })
  }
  async bootstrap() {
    this.spinner.show()
    let userId = this.auth.instance.getAllAccounts()[0].username
    this.userId = userId
    this.myCerts = await this.core.getCertByUser(userId).toPromise()

    this.certificates = await this.core.getAllCertificates().toPromise()
    if(this.certId) {
      this.selectedCert = this.certificates.find(cert => cert.id == this.certId)
    }
    this.spinner.hide()    
  }

  selectCertificate(cert: Certificate) {
    if(this.selectedCert == cert) {
      this.selectedCert = null
    } else {
      this.selectedCert = cert
    }
    
  }

  isAdded(certId: string) {
    return (this.myCerts.find(cert => cert.cert_id == certId) != null)
  }

  add() {    
    console.log(this.link);
    console.log(this.link.errors);
    
    
    if(this.selectedCert) {
      this.spinner.show()
      this.http.post(environment.logicAppEndpoint, {
        "cert_id": this.selectedCert.id,
        "action": this.verb,
        "cert_date": this.date,
        "verification_link": this.link
      }).subscribe(data => {
        this.spinner.hide()    
        this.router.navigate(['/my'])    
      })
    }
 
  }

  isValid(): boolean {
      return this.selectedCert != null && this.date != ''
  }

}
