import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';

import { BlobServiceClient } from '@azure/storage-blob'
import { Observable, interval } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CoreService, Certificate, UserCertificate, User } from '../services/core.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my',
  templateUrl: './my.component.html',
  styleUrls: ['./my.component.scss']
})
export class UserInfoComponent implements OnInit {
  certificates: Certificate[] = []
  myCerts: UserCertificate[] = []
  userId: string = ''
  isOwner: boolean = false
  account: User = new User();
  loaded = false
  constructor(private auth: MsalService,private core: CoreService, private route: ActivatedRoute, private http: HttpClient, private spinner: NgxSpinnerService, private router: Router) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(async paramMap => {
      let userId = paramMap.get('userId') || '';
      if(userId == '') {
        userId = this.auth.instance.getAllAccounts()[0].username
        this.isOwner = true
      } else {
        this.account = await this.core.getUser(userId).toPromise()
      }
      this.userId = userId
      
      this.bootstrap(userId)
    })



  }

  async bootstrap(userId: string) {
    this.spinner.show()
    this.myCerts = await this.core.getCertByUser(userId).toPromise()
    console.log(this.myCerts);
    
    this.core.getAllCertificates().subscribe(certs => {
      console.log(certs);
      
      this.certificates = certs.filter(cert => (this.myCerts.find(myCert => myCert.cert_id == cert.id) != null))
      this.spinner.hide()
      this.loaded = true
    })
  }

  delete(certId: string) {
    this.spinner.show(certId);

    this.http.post(environment.logicAppEndpoint, {
      "cert_id": certId,
      "action": "DELETE"
    }).subscribe(data => {
      console.log(data);
      this.spinner.hide(certId);
      this.bootstrap(this.userId)

    })

  }

  edit(certId: string) {
    let myCert = this.getMyCert(certId)
    if(myCert) {
      this.router.navigate(['/edit'], { queryParams: {certId: myCert.cert_id , certDate: myCert.cert_date, verificationLink: myCert.verification_link}})

    }
  }

  getMyCert(certId: string): UserCertificate | undefined {
    console.log(this.myCerts.find(myCert => myCert.cert_id == certId));
    
    return this.myCerts.find(myCert => myCert.cert_id == certId)
  }



}

