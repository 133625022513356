<h1>All certificates</h1>
<div class="filters">
    <div class="filter">
        <label class="filter__label">Platform</label>
        <div class="filter__field">
            <ng-select [items]="platformOptions" [(ngModel)]="selectedPlatform"
                (change)="filterUserCerts()" (clear)="filterUserCerts()" placeholder="Show all">
            </ng-select>
        </div>
    </div>
    <div class="filter">
        <label class="filter__label">Certificate</label>
        <div class="filter__field">
            <ng-select [items]="certOptions" bindLabel="name" bindValue="value" [(ngModel)]="selectedCert"
                (change)="filterUserCerts()" (clear)="filterUserCerts()" placeholder="Show all">
            </ng-select>
        </div>
    </div>

    <div class="filter">
        <label class="filter__label">User</label>
        <div class="filter__field">
            <ng-select [items]="userOptions" bindLabel="name" bindValue="value" [(ngModel)]="selectedUser"
                (change)="filterUserCerts()" (clear)="filterUserCerts()" placeholder="Show all">
            </ng-select>
        </div>
    </div>
    <div class="filter">
        <label class="filter__label">Sort</label>
        <div class="filter__field">
            <ng-select [items]="sortOptions" bindLabel="name" bindValue="value" [(ngModel)]="selectedSort"
                (change)="sortCert()" (clear)="sortCert()" [searchable]="false" [clearable]="false">
            </ng-select>
        </div>
    </div>



</div>
<div class="total">Total result: {{filteredUserCerts.length}}</div>
<div class="clear-filters" *ngIf="isFiltering">
    <button (click)="resetFilters()"><i class="bi bi-arrow-counterclockwise"></i> Reset filters</button>
</div>

<div class="groups">
    <div class="group" *ngFor="let group of groupedCerts">
        <div class="group__header"><strong>{{group.name}}</strong>
            ({{group.certs.length}})</div>
        <div class="certificates">
            <div class="certificate" *ngFor="let cert of group.certs">
                <div class="certificate__image">
                    <img class="certificate__image__user" [src]="(core.getUser(cert.email) | async)?.base64"
                        onerror="this.src='/assets/images/user-placeholder.png'">
                    <img class="certificate__image__cert" [src]="getCertById(cert.cert_id).base64"
                        onerror="this.src='/assets/images/cert-placeholder.png'">
                </div>
                <div class="certificate__text">
                    <div class="certificate__id" (click)="selectPlatformAndFilter(getCertById(cert.cert_id).platform)">
                        {{getCertById(cert.cert_id).platform}}</div>
                    <div class="certificate__name" (click)="selectCertAndFilter(cert)">
                        {{getCertById(cert.cert_id).title}}</div>
                    <div class="certificate__user" (click)="selectUserAndFilter(cert)">
                        {{(core.getUser(cert.email) | async)?.name }}</div>
                    <div class="certificate__date"><i class="bi bi-clock"></i> Completed on {{cert.cert_date | date}}
                        <a [href]="cert.verification_link" *ngIf="cert.verification_link" target="_blank"
                            class="verified"><i class="bi bi-patch-check-fill"></i></a>

                    </div>

                    <div *ngIf="cert"></div>

                </div>
            </div>
        </div>
    </div>
</div>


<div class="no-content" *ngIf="loaded && groupedCerts.length == 0 && isFiltering">
    There is no such entry that matches current critiria
</div>
<div class="no-content" *ngIf="loaded && groupedCerts.length == 0 && !isFiltering">
    Our system has zero record :( <br>Add one of yours!
</div>